<!--周边场所  -->
<template>
  <div class="">
    <a-row :gutter="16">
      <a-col class="gutter-row" :span="4">
        <silder-menu-list
          @changeTableName="changeTableName"
          :menuListData="menuListData"
          :searchTitle="searchTitle"
        ></silder-menu-list>
      </a-col>
      <a-col class="gutter-row" :span="20">
        <kindergarten v-if="tableName === '幼儿园'" />
        <school v-if="tableName === '学校'" />
        <enterprise v-if="tableName === '企业'" />
        <gasStation v-if="tableName === '加油站'" />
        <religioussite v-if="tableName === '宗教场所'" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import kindergarten from "./kindergarten/kindergarten.vue";
import school from "./school/school.vue";
import enterprise from "./enterprise/enterprise.vue";
import SilderMenuList from "./silderMenuList.vue";
import gasStation from "./gasStation/gasStation.vue";
import religioussite from "./religioussite/religioussite.vue";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const menuListData = ["幼儿园", "学校", "企业", "加油站", "宗教场所"];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SilderMenuList,
    kindergarten,
    school,
    enterprise,
    gasStation,
    religioussite,
  },
  data() {
    //这里存放数据
    return {
      menuListData,
      tableName: menuListData[0],
      searchTitle: "周边场所",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeTableName(item) {
      console.log(item);
      this.tableName = item;
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-list-item {
  justify-content: flex-start !important;
  margin: 2% 5%;
  background-color: #fff;
  border-radius: 4px;
}
</style>